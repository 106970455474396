import { Component, Input, OnChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { UsersModule } from '../../../users/users.module';
import { TowsService } from '../../../tows/service/tows.service';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { ICheckBlock } from '../../interface/check-block.interface';

@Component({
  standalone: true,
  imports: [RouterModule, UsersModule],
  selector: 'app-check-block-card',
  template: `
    <a
      class="container"
      [routerLink]="['/tows', sample.towId, 'samples', sample._id]"
      [queryParams]="{ checkblock: checkBlock._id, taxon: checkBlock.taxonId }"
    >
      <p style="opacity: 75%; margin: 0 0 8px">
        {{ tow?.metadata.oldTowBaseId }}-{{ sample.sampleNo }}
      </p>
      <div style="display: inline-block; margin: 0 8px 8px 0">
        <app-user-avatar
          [userId]="checkBlock.messages[checkBlock.messages.length - 1].userId"
          [showLabel]="false"
        />
      </div>
      <p style="display: inline-block; margin: 0">
        {{ checkBlock.messages[checkBlock.messages.length - 1].message }}
      </p>
    </a>
  `,
  styles: [
    `
      .container {
        display: block;
        color: black;
        text-decoration: none;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #fff;
        transition: background-color 80ms ease-in;
        width: 180px;
        height: 150px;
        overflow: hidden;

        &:hover {
          background-color: #5cb3a711;
          cursor: pointer;
        }
      }
    `,
  ],
})
export class CheckBlockCardComponent implements OnChanges {
  @Input() checkBlock: ICheckBlock;

  sample: ISample;
  tow: IRecordingEvent<'tow'>;

  constructor(
    private readonly samples: SamplesService,
    private readonly tows: TowsService,
  ) {}

  async ngOnChanges() {
    if (this.checkBlock?.sampleId) {
      this.sample = await this.samples.getOne('', this.checkBlock.sampleId);
      this.tow = await this.tows.getOne('', this.sample.towId);
    } else {
      this.sample = undefined;
    }
  }
}
