<div class="container">
  <button mat-icon-button class="close-button" mat-dialog-close (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-horizontal-stepper [linear]="true">
    <mat-step [stepControl]="checksValid">
      <div class="step-container">
        <div class="fill-space">
          <ng-template matStepLabel>Sample checks</ng-template>
          @if (!checks) {
            <suvo-core-loading-spinner message="Running checks..." />
          } @else {
            <div style="display: flex; flex-flow: column nowrap; gap: 24px">
              <div
                style="display: flex; align-items: center; gap: 12px"
                *ngFor="let check of checks ?? []"
              >
                <mat-icon color="primary" *ngIf="check[0]">check_circle</mat-icon>
                <mat-icon color="error" *ngIf="!check[0]">error</mat-icon>
                <span>{{ check[1] }}</span>
              </div>
            </div>
          }
        </div>
        <div class="buttons" style="justify-content: flex-end">
          <button
            mat-flat-button
            color="primary"
            matStepperNext
            [disabled]="!checksValid.value || finalising"
            (click)="finalise()"
          >
            Finalise
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
