import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SamplesService } from '../../service/samples.service';

export interface SampleFinaliseDialogData {
  sampleId: string;
  onConfirm: () => void;
}

@Component({
  selector: 'app-sample-finalise-dialog',
  styleUrls: ['./sample-finalise-dialog.component.scss'],
  templateUrl: './sample-finalise-dialog.component.html',
})
export class SampleFinaliseDialogComponent implements OnInit {
  checks: [valid: boolean, message: string][];
  checksValid = new FormControl<boolean>(undefined, Validators.required);

  finalising = false;

  constructor(
    private readonly dialogRef: MatDialogRef<SampleFinaliseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: SampleFinaliseDialogData,
    private readonly sampleService: SamplesService,
  ) {}

  async ngOnInit() {
    try {
      this.checks = await this.sampleService.check(this.dialogData.sampleId);
    } catch (e) {
      this.checks = e.error.message;
    }
    this.checksValid.setValue(this.checks.every(([valid]) => valid));
  }

  async finalise() {
    this.finalising = true;
    try {
      await this.sampleService.finalise(this.dialogData.sampleId);
    } catch (e) {
      this.finalising = false;
      return;
    }
    this.dialogRef.close();
    this.dialogData.onConfirm();
  }

  cancel() {
    this.sampleService.unfinalise(this.dialogData.sampleId);
    this.dialogRef.close();
  }
}
