<mat-card>
  <mat-card-header>
    <mat-card-title>Your active samples</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="filters">
      <div [formGroup]="filtersFormGroup">
        <suvo-bi-reference-autocomplete-selector
          referenceLabelProperty="label"
          entityDefinitionAlias="tows"
          formControlName="towId"
        />
      </div>
    </div>
    <table
      mat-table
      [dataSource]="dataSource$"
      matSort
      [defaultSort]="defaultSort"
      matSortDisableClear
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="listDropped($event)"
      *ngIf="count != 0"
    >
      <ng-container matColumnDef="towId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Tow</span>
        </th>
        <td mat-cell *matCellDef="let item">{{ item.tow?.metadata?.oldTowBaseId }}</td>
      </ng-container>

      <ng-container matColumnDef="sampleNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <span *ngIf="!isLoading">Sample no.</span>
        </th>
        <td mat-cell *matCellDef="let item">{{ item.sampleNo }}</td>
      </ng-container>

      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item" class="align-right">
          <button mat-button color="primary">Data Entry</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="headings"></tr>
      <tr
        mat-row
        class="row clickable"
        [class.hidden]="isLoading"
        *matRowDef="let row; columns: headings"
        [routerLink]="['/tows', row.tow?._id, 'samples', row._id]"
      ></tr>

      <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
        <td class="mat-cell" [attr.colspan]="headings.length">
          <span>No matching data.</span>
        </td>
      </tr>
    </table>

    <suvo-bi-skeleton-table-rows
      *ngIf="isLoading"
      [rowCount]="pageSize"
      [columnWidths]="widths"
    ></suvo-bi-skeleton-table-rows>

    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      *ngIf="count != 0"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
