import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { IMeasurement } from '../measurement.interface';
import { MeasurementService } from '../measurement.service';
import { IMeasurementUpdate, MeasurementEditDialog } from './measurement-edit-dialog.component';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
  selector: 'app-measurement-action-menu',
  template: `
    <mat-menu #menu="matMenu">
      <button mat-menu-item [disabled]="readonly" (click)="onEdit()">Edit Comment or Count</button>
      <button mat-menu-item [disabled]="readonly" (click)="onDelete()" style="color: indianred">
        Delete
      </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  `,
})
export class MeasurementActionMenuComponent {
  @Input() measurement: IMeasurement;

  /** If the measurement is readonly, i.e. sample is finalised, set to `true`. */
  @Input() readonly = false;

  /** Emits if the user has successfully deleted the measurement via the menu. */
  @Output() delete = new EventEmitter<void>();

  /** Emits the new measurement if the user successfully edits the measurement via the menu. */
  @Output() update = new EventEmitter<IMeasurement>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly measurements: MeasurementService,
  ) {}

  onDelete() {
    this.measurements.deleteMeasurement(this.measurement._id, () => this.delete.emit());
  }

  onEdit() {
    this.dialog.open(MeasurementEditDialog, {
      data: {
        measurement: this.measurement,
        onConfirm: async (update: IMeasurementUpdate) => {
          this.measurement = await this.measurements.setMeasurement({
            sampleId: this.measurement.sampleId,
            taxonId: this.measurement.taxonId,
            analysedCount: update.analysedCount,
            comment: update.comment,
          });
          this.update.emit(this.measurement);
        },
      },
    });
  }
}
