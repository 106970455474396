import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonComponentsModule } from '@suvo-bi-core';
import { SharedModule } from '../../shared/shared.module';
import {ReferenceAutocompleteSelectorComponent} from './components/reference-autocomplete-selector/reference-autocomplete-option/reference-autocomplete-selector.component';

@NgModule({
  declarations: [
    ReferenceAutocompleteSelectorComponent
  ],
  exports: [
    ReferenceAutocompleteSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    CommonComponentsModule
  ]
})
export class ReferencesModule {}
