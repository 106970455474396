import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ICheckBlock } from '../../interface/check-block.interface';
import { CheckBlockCardComponent } from '../card/check-block-card.component';

@Component({
  standalone: true,
  imports: [MatCardModule, CheckBlockCardComponent],
  selector: 'app-check-blocks-tile',
  template: `
    <mat-card>
      <mat-card-header class="between">
        <mat-card-title>Check Blocks</mat-card-title>
        <ng-content select="[header]" />
      </mat-card-header>
      <mat-card-content style="display: flex; flex-wrap: wrap; gap: 16px">
        @if (checkBlocks?.length) {
          @for (checkBlock of checkBlocks; track checkBlock._id) {
            <app-check-block-card [checkBlock]="checkBlock" />
          }
        } @else {
          <ng-content select="[whenNone]" />
        }
      </mat-card-content>
    </mat-card>
  `,
})
export class CheckBlocksTileComponent {
  @Input() checkBlocks: ICheckBlock[];

  ngOnChanges() {
    this.checkBlocks = this.checkBlocks.filter((cb) => !cb.resolved);
  }
}
