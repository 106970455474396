import { Component, computed, effect, inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { ITaxon } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { CheckBlockModule } from '../../../check-block/check-block.module';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatSidenavModule, RouterModule, CheckBlockModule],
  selector: 'app-data-entry-check-block-panel',
  template: `
    <mat-sidenav-container autosize>
      <mat-sidenav
        #panel
        mode="side"
        autoFocus="false"
        fixedInViewport="true"
        position="end"
        style="padding: var(--global-padding)"
        [opened]="checkBlockId() && sampleId() && taxonId()"
      >
        <div class="between">
          <h3 style="padding: 0">Checkblock Details</h3>
          <a
            mat-icon-button
            routerLink="."
            [queryParams]="{ checkblock: null, taxon: null }"
            queryParamsHandling="merge"
          >
            <mat-icon>close</mat-icon>
          </a>
        </div>

        @if (taxon) {
          <p>{{ taxon.taxonName }}</p>
        }

        @if (sampleId() && taxonId()) {
          <app-check-block-conversation [sampleId]="sampleId()" [taxonId]="taxonId()" />
        }
      </mat-sidenav>

      <mat-sidenav-content>
        <ng-content />
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
})
export class DataEntryCheckBlockPanelComponent implements OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly taxonService = inject(TaxonService);

  private readonly queryParamMap = toSignal(this.route.queryParamMap);
  private readonly paramMap = toSignal(this.route.paramMap);

  readonly checkBlockId = computed(() => this.queryParamMap().get('checkblock'));
  readonly sampleId = computed(() => this.paramMap().get('sampleID'));
  readonly taxonId = computed(() => this.queryParamMap().get('taxon'));

  taxon: ITaxon;

  private readonly unsubscribe$ = effect(async () => {
    if (this.taxonId()) {
      this.taxon = await this.taxonService.getById(this.taxonId());
    }
  });

  ngOnDestroy() {
    this.unsubscribe$.destroy();
  }
}
