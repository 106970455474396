import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SamplesService } from '../../../samples/service/samples.service';
import { TaxonService } from '../../../taxon/taxon.service';
import { UsersService } from '../../../users/service/users.service';
import { CheckBlockService } from '../../check-block.service';
import { ICheckBlockPopulated } from '../../interface/check-block.interface';

@Component({
  selector: 'app-check-block-conversation',
  templateUrl: './check-block-conversation.component.html',
})
export class CheckBlockConversationComponent implements OnChanges, OnDestroy, OnInit {
  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement>;

  @Input() sampleId: string;
  @Input() taxonId: string;

  @Output() deleted = new EventEmitter<void>();
  @Output() raised = new EventEmitter<void>();
  @Output() resolved = new EventEmitter<void>();

  readonly messageForm = new FormControl<string>('');

  currentUserId: string;
  sendMessageAllowed = false;
  checkBlock: ICheckBlockPopulated;

  raiseCheckBlockAllowed = false;
  raiseButtonClicked = false;

  pollTimeout: string | number | NodeJS.Timeout;

  constructor(
    private readonly authService: AngularFireAuth,
    private readonly checkBlockService: CheckBlockService,
    private readonly sampleService: SamplesService,
    private readonly taxonService: TaxonService,
    private readonly userService: UsersService,
  ) {}

  async ngOnInit() {
    this.authService.user.pipe(takeUntil(this.unsubscribe$)).subscribe(async (firebaseUser) => {
      const context = (await firebaseUser.getIdTokenResult()).claims.context;

      const checkBlockPermissions = context.permissions.checkblocks;

      this.currentUserId = (await this.userService.getMe())._id;

      this.raiseCheckBlockAllowed = checkBlockPermissions?.includes('create');

      this.updatePermissions();
    });

    this.pull();
  }

  async updatePermissions() {
    this.sendMessageAllowed = this.raiseCheckBlockAllowed;

    if (!this.sendMessageAllowed) {
      const sample = await this.sampleService.getOne('', this.sampleId);
      this.sendMessageAllowed = sample.userId === this.currentUserId;
    }

    if ((!this.checkBlock || !this.checkBlock.resolved) && this.sendMessageAllowed) {
      this.messageForm.enable();
    } else {
      this.messageForm.disable();
    }
  }

  async ngOnChanges() {
    await this.pull();

    this.messageForm.reset();

    this.updatePermissions();

    this.raiseButtonClicked = false;
  }

  ngOnDestroy() {
    this.stopPolling();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  stopPolling() {
    if (this.pollTimeout) {
      clearInterval(this.pollTimeout);
    }
  }

  startPolling() {
    this.stopPolling();
    this.pollTimeout = setInterval(() => this.pull(), 10000);
  }

  async pull() {
    this.checkBlock = await this.checkBlockService.getBySampleAndTaxon(this.sampleId, this.taxonId);
    if (this.checkBlock) {
      this.startPolling(); // Reset countdown
    } else {
      this.stopPolling();
    }
  }

  async addMessage() {
    if (this.checkBlock?.messages.length) {
      await this.checkBlockService.addMessage(this.sampleId, this.taxonId, this.messageForm.value);
    } else {
      await this.checkBlockService.raiseCheckBlock(
        this.sampleId,
        this.taxonId,
        this.messageForm.value,
      );
      this.raised.emit();
    }
    this.messageForm.reset();
    this.pull();
  }

  async raise() {
    const taxon = await this.taxonService.getById(this.taxonId);
    this.messageForm.setValue(`Any ${taxon.taxonName}? Please confirm.`);
    this.raiseButtonClicked = true;
    setTimeout(() => this.messageInput.nativeElement.focus());
  }

  onDeleted() {
    this.checkBlock = undefined;
    this.deleted.emit();
  }

  onResolved() {
    this.checkBlock.resolved = true;
    this.resolved.emit();
  }
}
