import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { TCountingMethod } from '../taxon/taxon.interface';
import { ITaxonAbundanceCategory } from './taxon-abundance-category.interface';

@Injectable()
export class TaxonAbundanceCategoryService {
  private categories: Record<TCountingMethod, ITaxonAbundanceCategory[]>;

  constructor(private readonly http: HttpClient) {}

  private filterAndSort(categories: ITaxonAbundanceCategory[], countingMethod: TCountingMethod) {
    return categories
      .filter((cat) => cat.countingMethod === countingMethod)
      .sort((a, b) => a.taxonCategory - b.taxonCategory);
  }

  async getAll() {
    if (this.categories) {
      return this.categories;
    }

    return (this.categories = await this.http
      .get<ITaxonAbundanceCategory[]>(environment.cprServiceApi + 'category')
      .toPromise()
      .then((result) => ({
        EYECOUNT: this.filterAndSort(result, 'EYECOUNT'),
        PHYTO: this.filterAndSort(result, 'PHYTO'),
        TRAVERSE: this.filterAndSort(result, 'TRAVERSE'),
      })));
  }

  async getForCountingMethod(countingMethod: TCountingMethod) {
    return (await this.getAll())[countingMethod];
  }

  async getForCount(countingMethod: TCountingMethod, count: number) {
    const categories = await this.getForCountingMethod(countingMethod);
    return categories.find((cat) => count <= cat.maximum);
  }
}
