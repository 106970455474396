import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TaxonAbundanceCategoryModule } from '../taxon-abundance-category/taxon-abundance-category.module';
import { TaxonHierarchyComponent } from './components/taxon-hierarchy.component';
import { TaxonHierarchyItemComponent } from './components/taxon-hierarchy-item.component';
import { TaxonService } from './taxon.service';

@NgModule({
  imports: [CommonModule, SharedModule, TaxonAbundanceCategoryModule],
  declarations: [TaxonHierarchyComponent, TaxonHierarchyItemComponent],
  providers: [TaxonService],
  exports: [TaxonHierarchyComponent, TaxonHierarchyItemComponent],
})
export class TaxonModule {}
