<div
  class="item"
  [class.bold]="showChildren && taxon.taxonChildren?.length"
  [class.no-measurement]="!measurement"
>
  <div style="flex-grow: 1">
    <span>{{ taxon.taxonName }}</span>
    @if (showOther) {
      <span style="color: #88f">({{ taxon.countingMethod === 'PHYTO' ? 'total' : 'extra' }})</span>
    }
    <span style="opacity: 50%" *ngIf="showID"> (ID: {{ taxon.cprTaxonId }})</span>
  </div>
  <ng-container *ngIf="measurement">
    <span *ngIf="showCategory && category" style="opacity: 50%">(Category {{ category }})</span>
    <span style="min-width: 40px; text-align: right">{{ measurement?.analysedCount }}</span>
    <ng-content></ng-content>
    <button
      mat-icon-button
      *ngIf="showDelete"
      [disabled]="sample?.finalisedAt"
      (click)="deleteMeasurement()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
</div>

<div class="children" *ngIf="showChildren && taxon.taxonChildren?.length">
  <app-taxon-hierarchy-item
    *ngIf="fakeOtherTaxon?.measurement?.analysedCount > 0"
    [taxon]="fakeOtherTaxon"
    [showCategory]="showCategory"
    [showID]="false"
    [showOther]="true"
  />
  <app-taxon-hierarchy-item
    *ngFor="let child of taxon.taxonChildren"
    [taxon]="child"
    [showCategory]="showCategory"
  />
</div>
