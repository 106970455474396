import { Component, Input } from '@angular/core';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { UsersService } from '../../../users/service/users.service';
import { ISample } from '../../interface/sample.interface';
import { SamplesService } from '../../service/samples.service';
import { ICprUser } from '../../../users/interface/user.interface';

@Component({
  selector: 'app-samples-table',
  templateUrl: './samples-table.component.html',
  styleUrls: ['./samples-table.component.scss'],
})
export class SamplesTableComponent extends TableComponent<ISample, string> {
  readonly headings = [
    'towId',
    'sampleNo',
    'sampleLatitude',
    'sampleLongitude',
    'chlorophyllIndex',
    'userId',
    'status',
    'button',
  ];
  readonly widths = [12, 10, 10, 20, 10, 10, 10, 12];

  @Input() mode: 'simple' | 'full' = 'full';

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  defaultSort = 'SKIP';

  count: number;

  user: ICprUser;

  constructor(
    sessionService: SessionService,
    private readonly samples: SamplesService,
    private readonly users: UsersService,
  ) {
    super(sessionService);
  }

  async ngOnInit() {
    this.user = await this.users.getMe();
    super.ngOnInit();
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<ISample>> {
    return this.samples.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    });
  }
}
