import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { TaxonAbundanceCategoryService } from './taxon-abundance-category.service';
import { AbundanceCategoriesComponent } from './abundance-categories.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [AbundanceCategoriesComponent],
  providers: [TaxonAbundanceCategoryService],
  exports: [AbundanceCategoriesComponent],
})
export class TaxonAbundanceCategoryModule {}
