import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { UsersService } from '../../../users/service/users.service';
import { ICheckBlockMessage } from '../../interface/check-block-message.interface';
import { UsersModule } from '../../../users/users.module';

@Component({
  standalone: true,
  imports: [MatCardModule, UsersModule],
  selector: 'app-check-block-message',
  template: `
    <div class="container">
      <app-user-avatar
        [userId]="message.userId"
        [showLabel]="false"
        [style.grid-column]="me ? 3 : 1"
      />
      <mat-card class="message" [class.me]="me">
        {{ message.message }}
      </mat-card>
    </div>
  `,
  styles: [
    `
      .container {
        display: grid;
        grid-template-columns: 32px auto 32px;
        gap: 16px;
      }

      .message {
        margin-top: 24px;
        padding: 10px 16px;
        grid-row: 1;
        grid-column: 2;

        &.me {
          border-top-right-radius: 0;
          background-color: var(--primary-lighter-color);
        }

        &:not(.me) {
          border-top-left-radius: 0;
        }
      }
    `,
  ],
})
export class CheckBlockMessageComponent implements OnInit {
  /** The message from the check block messages array. */
  @Input() message: ICheckBlockMessage;

  /** Whether the message was authored by the current user. */
  @Input() me: boolean;

  constructor(private readonly users: UsersService) {}

  async ngOnInit() {
    if (this.me === undefined) {
      const { _id } = await this.users.getMe();
      this.me = _id === this.message.userId;
    }
  }
}
