import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { UsersModule } from '../users/users.module';
import { CheckBlockService } from './check-block.service';
import { CheckBlockConversationComponent } from './components/conversation/check-block-conversation.component';
import { CheckBlockMenuComponent } from './components/menu/check-block-menu.component';
import { CheckBlockMessageComponent } from './components/message/message.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UsersModule,
    CheckBlockMenuComponent,
    CheckBlockMessageComponent,
  ],
  declarations: [CheckBlockConversationComponent],
  providers: [CheckBlockService],
  exports: [CheckBlockConversationComponent, CheckBlockMenuComponent],
})
export class CheckBlockModule {}
