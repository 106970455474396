import { Component, Input } from '@angular/core';
import {
  FilterConditionType,
  FilterOperatorType,
  FiltersChannel,
  IItemsTableOptions,
  ITableDataSource,
  SessionService,
} from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { UsersService } from '../../../users/service/users.service';
import { ISample } from '../../interface/sample.interface';
import { SamplesService } from '../../service/samples.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { takeUntil } from 'rxjs/operators';
import { property } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { query } from '@angular/animations';

@Component({
  selector: 'app-my-active-samples',
  templateUrl: './my-active-samples.component.html',
  styleUrls: ['./my-active-samples.component.scss'],
})
export class MyActiveSamplesComponent extends TableComponent<ISample, string> {
  readonly headings = ['towId', 'sampleNo', 'button'];
  readonly widths = [12, 10, 10, 20, 10, 10, 10, 12];

  @Input() mode: 'simple' | 'full' = 'full';

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  defaultSort = { active: 'towId', direction: 'asc' };

  count: number;

  userId;

  readonly filtersFormGroup = new FormGroup({
    towId: new FormControl<string>(''),
  });

  constructor(
    sessionService: SessionService,
    private readonly samples: SamplesService,
    public readonly users: UsersService,
    private userService: UsersService,
  ) {
    super(sessionService);
  }

  async ngOnInit() {
    this.userId = (await this.userService.getMe())?._id;

    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((values) => {
      this.refresh();
    });

    super.ngOnInit();
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<ISample>> {
    let extraFilters = {
      name: 'temp my sample filters',
      query: {
        op: FilterOperatorType.And,
        rules: [
          {
            key: 'userId',
            type: 'OBJECT_ID',
            con: [
              {
                type: FilterConditionType.Equals,
                val: `${this.userId}`,
              },
            ],
          },
          {
            op: FilterOperatorType.Or,
            rules: [
              {
                key: 'finalisedAt',
                con: [
                  {
                    type: FilterConditionType.NotExists,
                  },
                ],
              },
              {
                key: 'finalisedAt',
                con: [
                  {
                    type: FilterConditionType.Equals,
                    val: null,
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    let towIds = this.filtersFormGroup.controls['towId'].value;

    if (towIds && towIds.length) {
      extraFilters.query.rules.push({
        key: 'towId',
        type: 'OBJECT_ID',
        con: [{ type: FilterConditionType.Equals, val: towIds[0] }],
      });
    }

    return this.samples.getPaginated('', {
      filters: extraFilters,
      sort,
      pagination,
      search,
    });
  }
}
