export const STATUSES = [
  'created',
  'prepared',
  'dispatched',
  'returned',
  'cut',
  'analysing',
  'finalised',
  'finalising',
  'cancelled',
] as const;

export type TStatus = (typeof STATUSES)[number];
