import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { ICuttingPoint } from '../interface/cutting-point.interface';

@Injectable()
export class CuttingPointService extends TableApiService<ICuttingPoint> {
  constructor(http: HttpClient) {
    super(http, `${environment.cprServiceApi}cutting-point/`);
  }

  getPaginated(prefixes: string | string[], options: ITableOptions & { towId?: string }) {
    return super.getPaginated(prefixes, options);
  }
}
