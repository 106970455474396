<mat-divider style="margin-bottom: var(--global-padding)" />

@if (checkBlock?.messages || raiseButtonClicked) {
  <header style="display: flex">
    <h3 style="padding-top: 0; padding-bottom: 0; margin-top: 4px; margin-bottom: 0">Checkblock</h3>
    <div style="margin-left: auto">
      <app-check-block-menu
        [checkBlock]="checkBlock"
        (deleted)="onDeleted()"
        (resolved)="onResolved()"
      />
    </div>
  </header>

  @if (checkBlock?.messages?.length) {
    <div style="margin-top: 20px">
      @for (message of checkBlock?.messages; track message._id) {
        <app-check-block-message [message]="message" [me]="message.userId === currentUserId" />
      }
    </div>
  }

  <div
    *ngIf="checkBlock?.resolved"
    style="margin-top: var(--global-padding); text-align: center; color: grey"
  >
    This check block has been marked as resolved.
  </div>
  <div style="display: flex; margin-top: var(--global-padding)" *ngIf="!checkBlock?.resolved">
    <mat-form-field floatLabel="never" style="flex-grow: 1">
      <textarea
        #messageInput
        matInput
        required
        minlength="1"
        [formControl]="messageForm"
        [placeholder]="
          sendMessageAllowed
            ? ''
            : 'You do not have permission to send messages in this conversion.'
        "
        (input)="messageForm.markAsUntouched()"
        (blur)="messageForm.markAsUntouched()"
        (keyup.enter)="addMessage()"
      ></textarea>
    </mat-form-field>
    <button
      mat-icon-button
      style="margin-top: 9px"
      [disabled]="checkBlock?.resolved || !sendMessageAllowed || !messageForm.valid"
      (click)="addMessage()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
} @else {
  <button
    mat-flat-button
    color="primary"
    [disabled]="!raiseCheckBlockAllowed"
    (click)="raise()"
    style="width: 100%"
    [matTooltip]="raiseCheckBlockAllowed ? undefined : 'You do not have the required permission'"
  >
    Raise checkblock
  </button>
}
