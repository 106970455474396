import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TCountingMethod } from '../taxon/taxon.interface';
import { ITaxonAbundanceCategory } from '../taxon-abundance-category/taxon-abundance-category.interface';
import { TaxonAbundanceCategoryService } from '../taxon-abundance-category/taxon-abundance-category.service';

@Component({
  selector: 'app-abundance-categories',
  template: `
    <mat-expansion-panel
      #expansionPanel
      [expanded]="false"
      style="margin-bottom: var(--global-padding); background: var(--primary-lighter-color)"
      [disabled]="countingMethod === 'PHYTO'"
    >
      <mat-expansion-panel-header>
        <span style="text-transform: capitalize" *ngIf="countingMethod !== 'PHYTO'">
          {{ countingMethod.toLowerCase() }} Abundance Categories
        </span>
        <span style="text-transform: capitalize" *ngIf="countingMethod === 'PHYTO'">
          Abundance Categories (unavailable for {{ countingMethod.toLowerCase() }})
        </span>
      </mat-expansion-panel-header>
      <table style="text-align: center" *ngIf="categories">
        <thead>
          <tr>
            <th>Category</th>
            <th>Min</th>
            <th>Max</th>
            <th>Mean</th>
            <th>Mean Per Sample</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cat of categories[countingMethod]">
            <td>{{ cat.taxonCategory }}</td>
            <td>{{ cat.minimum }}</td>
            <td>{{ cat.maximum }}</td>
            <td>{{ cat.mean }}</td>
            <td>{{ cat.meanPerSample }}</td>
          </tr>
        </tbody>
      </table>
    </mat-expansion-panel>
  `,
})
export class AbundanceCategoriesComponent implements OnChanges, OnInit {
  @Input() countingMethod: TCountingMethod;

  @ViewChild('expansionPanel') panel: MatExpansionPanel;

  categories: Record<TCountingMethod, ITaxonAbundanceCategory[]>;

  constructor(private readonly abundanceCategoryService: TaxonAbundanceCategoryService) {}

  async ngOnInit() {
    this.categories = await this.abundanceCategoryService.getAll();
  }

  ngOnChanges() {
    if (this.countingMethod === 'PHYTO') {
      this.panel?.close();
    }
  }
}
