<app-data-entry-check-block-panel>
  <app-header>
    <app-sample-data-entry-sample-switcher />

    <a mat-flat-button routerLink="..">Sample Analysis Overview</a>

    @if (!sample?.finalisedAt) {
      <button
        mat-stroked-button
        (click)="sample.isFinalising ? cancelFinalising() : finaliseSample()"
        [checkPermission]="['sample', 'finalise']"
      >
        {{ sample?.isFinalising ? 'Cancel Finalising' : 'Finalise' }}
      </button>
    }
  </app-header>

  @if (sample) {
    <section class="row">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Key information</mat-card-title>
        </mat-card-header>
        <mat-card-content class="key-info">
          <div>
            <h3>Tow</h3>
            <p>{{ tow.metadata?.oldTowBaseId ?? tow._id }}</p>
          </div>
          <div>
            <h3>Sample no.</h3>
            <p>{{ sample.sampleNo }}</p>
          </div>
          <div>
            <h3>Location</h3>
            <p>{{ sample.sampleLatitude }}, {{ sample.sampleLongitude }}</p>
          </div>
          <div>
            <h3>Midpoint Date</h3>
            <p>{{ sample.sampleMidpointDate | date: 'medium' : 'UTC' }}</p>
          </div>
          <div>
            <h3>PCI colour</h3>
            <app-pci-colour [value]="sample.chlorophyllIndex"></app-pci-colour>
          </div>
          <div>
            <h3>Analyst</h3>
            <app-user-avatar [userId]="sample.userId"></app-user-avatar>
          </div>
          <div>
            <h3>Status</h3>
            <app-status-chip
              [value]="
                sample.finalisedAt ? 'finalised' : sample.isFinalising ? 'finalising' : 'analysing'
              "
            />
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="sample-details">
        <mat-card-header>
          <mat-card-title>Sample details</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-edit
            [formGroup]="detailsFormGroup"
            permission="sample.analyse"
            (save)="onSaveDetails($event)"
            #editComponent
          >
            <mat-form-field>
              <mat-label>Microscope</mat-label>
              <mat-select formControlName="microscopeId">
                <mat-option *ngFor="let microscope of microscopes" [value]="microscope._id">
                  <b>{{ microscope.oldMicroscopeId }}</b>
                  {{ microscope.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field>
              <mat-label>Comments</mat-label>
              <textarea matInput cdkTextareaAutosize formControlName="comment"></textarea>
            </mat-form-field>
          </app-edit>
        </mat-card-content>
      </mat-card>
    </section>
  }

  <section>
    <app-data-entry-check-blocks />
  </section>

  <section>
    @if (measurementsAreLoading) {
      <suvo-core-loading-spinner />
    } @else {
      <mat-card class="measurements">
        <mat-card-header>
          <mat-card-title>Measurements</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div style="display: flex; justify-content: space-between; gap: 22px">
            <div>
              <div>
                <mat-button-toggle-group
                  [(value)]="countingMethod"
                  (change)="onCountingMethodChange($event)"
                >
                  <mat-button-toggle value="PHYTO">Phyto</mat-button-toggle>
                  <mat-button-toggle value="TRAVERSE">Traverse</mat-button-toggle>
                  <mat-button-toggle value="EYECOUNT">Eyecount</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <br />
              <p style="opacity: 70%; margin-top: 0">
                Use the form below to add measurements to the sample. The measurements that have
                been added to the sample appear in the left hand panel. Group totals are
                auto-calculated and displayed as a hierarchy in the right hand panel.
              </p>
            </div>

            <div style="width: 100%; max-width: 600px; padding-top: 60px">
              <app-abundance-categories [countingMethod]="countingMethod" />
            </div>
          </div>

          <div style="margin: 32px 0">
            @if (!this.sample.finalisedAt) {
              <div class="measurement-form-container">
                <h3 style="text-align: center; margin-top: 10px">Add measurement</h3>
                <form
                  [formGroup]="measurementFormGroup"
                  class="measurement-form"
                  [checkPermission]="['sample', 'analyse']"
                  style="display: flex; gap: var(--global-padding)"
                >
                  <mat-form-field style="width: 100%">
                    <mat-label>Taxon</mat-label>
                    <input
                      required
                      matInput
                      formControlName="taxon"
                      [matAutocomplete]="auto"
                      (input)="onInput()"
                    />
                  </mat-form-field>
                  <mat-form-field style="max-width: 150px">
                    <mat-label>Quantity</mat-label>
                    <input required matInput pattern="^(\d+|\+)$" formControlName="quantity" />
                  </mat-form-field>
                  <button
                    mat-flat-button
                    [disabled]="measurementFormGroup.invalid"
                    (click)="addMeasurement()"
                    style="margin: 3.5px 0 22.313px"
                  >
                    Add
                  </button>
                </form>
              </div>
            }
          </div>

          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="48px">
            <div>
              <div class="list-heading">
                <span>Added Taxon Measurements</span>
                <span style="padding-right: 100px">Count</span>
              </div>
              @for (measurement of processedMeasurements; track measurement) {
                @if (measurement.taxon.countingMethod === countingMethod) {
                  <app-measurement-list-item
                    [taxon]="measurement.taxon"
                    [measurement]="measurement.measurement"
                    [bracketText]="
                      measurement.other ? (countingMethod === 'PHYTO' ? 'total' : 'extra') : null
                    "
                    (delete)="pull()"
                    (update)="pull()"
                  />
                }
              }
              <div *ngIf="!hasMeasurementsForCountingMethod(countingMethod)" class="note">
                No taxons have been added for this counting type.
              </div>
            </div>
            <div>
              <div>
                <div class="list-heading">
                  <span>Calculated Taxon Totals</span>
                  <span style="min-width: 40px">Count</span>
                </div>
                <app-taxon-hierarchy
                  [countingMethod]="countingMethod"
                  [measurements]="measurements ?? []"
                  [showCategories]="true"
                />
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    }
  </section>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTaxonDisplayValue">
    <ng-container *ngFor="let option of taxonSearch">
      <mat-option *ngIf="option.countingMethod === countingMethod" [value]="option">
        <span>{{ option.taxonName }}</span>
        <span style="opacity: 50%"> (ID: {{ option.cprTaxonId }})</span>
        <span style="color: #00f8" *ngIf="option.taxonChildren?.length">
          ({{ countingMethod === 'PHYTO' ? 'total' : 'extra' }})</span
        >
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</app-data-entry-check-block-panel>
