import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CheckBlocksTileComponent } from '../../../check-block/components/tile/check-blocks-tile.component';
import { ICheckBlock } from '../../../check-block/interface/check-block.interface';
import { SamplesService } from '../../service/samples.service';

@Component({
  standalone: true,
  imports: [CheckBlocksTileComponent],
  providers: [SamplesService],
  selector: 'app-data-entry-check-blocks',
  template: `
    <app-check-blocks-tile [checkBlocks]="checkBlocks">
      <i whenNone>No check blocks to display.</i>
    </app-check-blocks-tile>
  `,
})
export class DataEntryCheckBlocksComponent {
  private readonly unsubscribe$ = new Subject<void>();

  checkBlocks: ICheckBlock[];

  constructor(route: ActivatedRoute, sampleService: SamplesService) {
    route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async ({ sampleID }) => {
      this.checkBlocks = await sampleService.getCheckBlocks(sampleID);
    });
  }
}
