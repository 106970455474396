import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { IRecordingEvent } from '../interface/recording-event.interface';
import { TowsService } from '../service/tows.service';
import { BreadcrumbsService } from '@suvo-bi-core';

@Injectable()
export class TowResolver implements Resolve<IRecordingEvent<'tow'>> {
  constructor(
    private readonly router: Router,
    private readonly breadcrumb: BreadcrumbsService,
    private readonly towService: TowsService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<IRecordingEvent<'tow'>> {
    const tow = await this.towService.getOne('', route.params['id']);
    if (tow) {
      this.breadcrumb.setDynamicNameSwap('tow-id', tow.metadata?.oldTowBaseId ?? tow._id);
      return tow;
    } else {
      await this.router.navigate(['tows']);
      throw new TypeError(`Tow not found: ${route.params['id']}`);
    }
  }
}
