import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { IMeasurement } from './measurement.interface';

@Injectable()
export class MeasurementService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
  ) {}

  setMeasurement(measurement: IMeasurement) {
    return lastValueFrom(
      this.http.put<IMeasurement>(`${environment.cprServiceApi}measurement`, measurement),
    );
  }

  deleteMeasurement(id: string, onConfirm?: () => void) {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: async () => {
          await lastValueFrom(this.http.delete(`${environment.cprServiceApi}measurement/${id}`));
          onConfirm?.();
        },
        title: 'Delete Measurement',
        body: 'Are you sure you want to delete the measurement?',
      },
    });
  }
}
