import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pci-colour',
  template: `
    <div class="container" *ngIf="value !== undefined">
      <span
        [class.colour-box]="opacity !== undefined"
        [style.background-color]="'rgba(0, 192, 64, ' + opacity + ')'"
        [matTooltip]="showLabel === 'tooltip' ? value : undefined"
        [matTooltipPosition]="matTooltipPosition"
      ></span>
      <span *ngIf="showLabel === 'label'">{{ value }}</span>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        gap: 16px;
        align-items: center;
      }

      .colour-box {
        min-height: 16px;
        min-width: 16px;
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    `,
  ],
})
export class PciColourComponent implements OnChanges {
  @Input() value: number;
  @Input() showLabel: 'label' | 'tooltip' | 'none' = 'label';
  @Input() matTooltipPosition = 'right';
  opacity: number;

  ngOnChanges() {
    this.opacity = Math.floor(this.value * 1.5) / 7;
  }
}
