<div class="container">
  <button mat-icon-button class="close-button" mat-dialog-close><mat-icon>close</mat-icon></button>
  <mat-horizontal-stepper [linear]="true">
    <mat-step [stepControl]="checksValid">
      <div class="step-container">
        <div class="fill-space">
          <ng-template matStepLabel>Tow sample checks</ng-template>
          <suvo-core-loading-spinner *ngIf="!checks"></suvo-core-loading-spinner>
          <div *ngIf="checks" style="display: flex; flex-flow: column nowrap; gap: 24px">
            <ng-container *ngFor="let sampleChecks of checks | keyvalue">
              <h4>Sample {{ sampleChecks.key }}</h4>
              <div
                style="display: flex; align-items: center; gap: 12px"
                *ngFor="let check of sampleChecks.value ?? []"
              >
                <mat-icon color="primary" *ngIf="check[0]">check_circle</mat-icon>
                <mat-icon color="error" *ngIf="!check[0]">error</mat-icon>
                <span>{{ check[1] }}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="buttons" style="justify-content: flex-end">
          <button
            mat-flat-button
            color="primary"
            matStepperNext
            [disabled]="!checksValid.value || finalising"
            (click)="finalise()"
          >
            Finalise
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
