import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SamplesService } from '../../service/samples.service';

export interface TowFinaliseDialogData {
  towId: string;
  onConfirm: () => void;
}

@Component({
  selector: 'app-tow-finalise-dialog',
  styleUrls: ['./tow-finalise-dialog.component.scss'],
  templateUrl: './tow-finalise-dialog.component.html',
})
export class TowFinaliseDialogComponent implements OnInit {
  checks: Record<number, [valid: boolean, message: string][]>;
  checksValid = new FormControl<boolean>(undefined, Validators.required);

  finalising = false;

  constructor(
    private readonly dialogRef: MatDialogRef<TowFinaliseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: TowFinaliseDialogData,
    private readonly sampleService: SamplesService,
  ) {}

  async ngOnInit() {
    try {
      this.checks = await this.sampleService.checkTow(this.dialogData.towId);
    } catch (e) {
      this.checks = e.error.checks;
    }
    this.checksValid.setValue(
      Object.values(this.checks).every((checks) => checks.every(([valid]) => valid)),
    );
  }

  async finalise() {
    this.finalising = true;
    try {
      await this.sampleService.finaliseTow(this.dialogData.towId);
    } catch (e) {
      this.finalising = false;
      return;
    }
    this.dialogRef.close();
    this.dialogData.onConfirm();
  }
}
