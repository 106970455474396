import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PermissionsService } from '../../../permissions/permissions.service';
import { CheckBlockService } from '../../check-block.service';
import { ICheckBlock } from '../../interface/check-block.interface';

@Component({
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule, RouterModule],
  selector: 'app-check-block-menu',
  template: `
    <mat-menu #menu="matMenu">
      @if (checkBlock) {
        <a mat-menu-item [routerLink]="['/checkblock', checkBlock._id, 'print']">Print</a>
        @if (isSenior) {
          @if (!checkBlock.resolved) {
            <button mat-menu-item (click)="onResolve()">Mark as resolved</button>
          }
          <button mat-menu-item style="color: indianred" (click)="onDelete()">Cancel</button>
        }
      }
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  `,
})
export class CheckBlockMenuComponent {
  @Input() checkBlock: ICheckBlock;

  @Output() deleted = new EventEmitter<void>();
  @Output() resolved = new EventEmitter<void>();

  isSenior: boolean;

  constructor(
    private readonly checkBlocks: CheckBlockService,
    permissions: PermissionsService,
  ) {
    permissions.hasPermission('checkblocks', 'create').then((has) => (this.isSenior = has));
  }

  onDelete() {
    this.checkBlocks.delete(this.checkBlock._id, () => this.deleted.emit());
  }

  onResolve() {
    this.checkBlocks.closeCheckBlock(this.checkBlock._id, () => this.resolved.emit());
  }
}
