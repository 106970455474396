import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ICheckBlock, ICheckBlockPopulated } from './interface/check-block.interface';

@Injectable()
export class CheckBlockService {
  private readonly base = `${environment.cprServiceApi}check-block`;

  constructor(
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
  ) {}

  delete(id: string, callback?: () => void) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: async () => {
          await lastValueFrom(this.http.delete(`${this.base}/${id}`));
          callback?.();
        },
        title: 'Cancel Check Block',
        body: 'Are you sure you want to cancel this check block? The conversation will be lost.',
      },
    });
  }

  get(id: string) {
    return lastValueFrom(this.http.get<ICheckBlock>(`${this.base}/${id}`));
  }

  getBySampleAndTaxon(sampleid: string, taxonid: string) {
    return lastValueFrom(
      this.http.get<ICheckBlockPopulated>(this.base, {
        params: { sampleid, taxonid },
      }),
    );
  }

  addMessage(sampleId: string, taxonId: string, message: string) {
    return lastValueFrom(
      this.http.post(`${this.base}/message`, {
        message,
        sampleId,
        taxonId,
      }),
    );
  }

  raiseCheckBlock(sampleId: string, taxonId: string, message: string) {
    return lastValueFrom(
      this.http.post(this.base, {
        message,
        sampleId,
        taxonId,
      }),
    );
  }

  closeCheckBlock(id: string, callback?: () => void) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: async () => {
          await lastValueFrom(this.http.post(`${this.base}/${id}/close`, ''));
          callback?.();
        },
        title: 'Mark Check Block as Resolved',
        body: 'This action will mark the check block as resolved and leave it in a read-only state.',
      },
    });
  }

  getUserActiveCheckBlocks(userId: string, limit?: number) {
    return lastValueFrom(
      this.http.get<ICheckBlock[]>(
        `${this.base}/activeforuser/${userId}`,
        limit ? { params: { limit } } : undefined,
      ),
    );
  }
}
